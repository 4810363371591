import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["wrapper", "search"]

  connect() {
    this.timeToClose = null
  }

  show(){
    if(this.hasWrapperTarget) {
      this.wrapperTarget.classList.add("search_active")
      
      this.blur()
    }
  }

  hover(){
    clearTimeout(this.timeToClose)
  }

  blur() {
    if(this.timeToClose) {
      clearTimeout(this.timeToClose)
    }

    this.timeToClose = setTimeout(()=> this.removeClass(), 2000)
  }

  hide(){
    clearTimeout(this.timeToClose)
    setTimeout(()=> this.removeClass(), 200)
  }

  removeClass(){
    if(this.hasWrapperTarget) {
      this.wrapperTarget.classList.remove("search_active")
    }
  }

  searchFromHistory(e){
    e.preventDefault()
    this.searchTarget.value = e.target.innerText
    this.dispatch("searchFromHistory")
  }
}
